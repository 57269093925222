.project-page {
    width: 100%;
    min-height: calc(100vh - 80px);
    font-family: 'Inter', sans-serif;
  }
  
  .project-container {
    max-width: 80rem;
    margin: 0 auto;
    padding: 2rem 1.5rem;
  }
  
  .project-hero {
    text-align: center;
    margin: 4rem 0 6rem;
  }
  
  .project-hero h1 {
    font-size: 4rem;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 2rem;
  }
  
  .project-subtitle {
    font-size: 1.25rem;
    line-height: 1.6;
    color: #6b7280;
    max-width: 48rem;
    margin: 0 auto;
  }
  
  .project-main-image {
    width: 100%;
    margin-bottom: 6rem;
  }
  
  .hero-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .project-info-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    margin: 0 auto;
    max-width: 90%;
  }
  
  .info-section {
    padding: 1rem;
    border-radius: 0.5rem;

  }
  
  .info-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 1rem;
    position: relative;
  }
  
  .info-title::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    width: 2rem;
    height: 2px;
    background-color: #2563eb;
  }
  
  .info-text {
    font-size: 1rem;
    line-height: 1.6;
    color: #6b7280;
  }
  
  @media (max-width: 1024px) {
    .project-info-grid {
      grid-template-columns: repeat(2, 1fr);
      max-width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .project-container {
      padding: 1rem;
    }
  
    .project-hero h1 {
      font-size: 3rem;
    }
  
    .project-subtitle {
      font-size: 1.125rem;
    }
  
    .project-info-grid {
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }
  
    .info-section {
      padding: 1.5rem;
    }
  }

  .video-section {
    margin: 6rem 0;
    width: 100%;
  }
  
  .video-title {
    font-size: 2rem;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .video-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%; 
    background-color: #f3f4f6;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .youtube-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 8px;
  }
  
  @media (max-width: 768px) {
    .video-section {
      margin: 3rem 0;
    }
  
    .video-title {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

.gallery-section {
    margin: 6rem auto;
    width: 100%;
    max-width: 80rem;
  }
  
  .gallery-title {
    font-size: 2rem;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 3rem;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    width: 100%;
  }
  
  .gallery-item {
    position: relative;
    width: 100%;
    padding-top: 56.25%; 
    background-color: #f3f4f6;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .gallery-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
 
  .gallery-item:hover .gallery-image {
    transform: scale(1.05);
  }
  
  
  @keyframes imageLoad {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .gallery-image {
    animation: imageLoad 0.5s ease-in;
  }
  
  
  @media (max-width: 1024px) {
    .gallery-section {
      margin: 4rem auto;
      padding: 0 2rem;
    }
    
    .gallery-grid {
      gap: 1.5rem;
    }
  }
  
  @media (max-width: 768px) {
    .gallery-section {
      margin: 3rem auto;
      padding: 0 1rem;
    }
  
    .gallery-title {
      font-size: 1.75rem;
      margin-bottom: 2rem;
    }
  
    .gallery-grid {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }
  
  
  .gallery-item {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 
                0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  
  .gallery-image:focus {
    outline: 2px solid #2563eb;
    outline-offset: 2px;
  }