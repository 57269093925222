.home-content {
  padding: 4rem 1.5rem;
  max-width: 80rem;
  margin: 0 auto;
  min-height: calc(70vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.hero-section h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  color: #1f2937;
  text-align: left;
  margin: 0;
}

.hero-section p {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #4b5563;
  font-weight: 400;
  margin-top: 2rem;
}

.slider-frame {
  display: inline-block;
  height: 4.2rem;
  overflow: hidden;
  min-width: 300px;
  vertical-align: top; 
  margin-left: 0.2em;
}

.slides-list {
  list-style: none;
  margin: 0;
  padding: 0;
  transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  text-align: left;
}

.slide-item {
  height: 4.2rem;
  line-height: 4.2rem;
  color: #07d600;
  white-space: nowrap;
  display: flex; 
  align-items: center;
  transition: 2s ease;
}

.role-wrapper:hover .slide-item {
  color: #8800ff;
}

@media (max-width: 768px) {
  .home-content {
    padding: 2rem 1rem;
  }

  .hero-section h1 {
    font-size: 2.5rem;
  }

  .hero-section p {
    font-size: 1.25rem;
  }
  
  .slider-frame {
    min-width: 240px;
    height: 3rem;
  }

  .slide-item {
    height: 3rem;
    line-height: 3rem;
  }
}