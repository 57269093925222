@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

.header {
  width: 100%;
  padding: 1rem 1.5rem;
  height: 80px; 
  display: flex;
  align-items: center; 
}

.nav-container {
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.logo {
  text-decoration: none;
  transition: color 0.2s ease;
  line-height: 1;
  height: auto;
  width: auto; 
}

.logo:hover {
  color: #6ab493;
}

.nav-links {
  display: flex;
  gap: 2rem;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  align-items: center;
  z-index: 1000;
}

.nav-links li {
  height: 100%;
  display: flex;
  align-items: center; 
  width: 100%;
}

.nav-links li a {
  font-family: 'Montserrat', sans-serif;
  color: #1f2937;
  text-decoration: none;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%; 
  display: block; 
  padding: 0.75rem 1rem; 
  border-radius: 8px;
}

.nav-links li a:hover {
  color: #4b5563;
}

.nav-links li.active a {
  color: #07d600;
}

.nav-links.active::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: rgba(7, 214, 0, 0.05);
}


.mobile-menu-btn {
  display: none;
  width: 24px;
  height: 24px;
  position: relative;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.mobile-menu-btn span {
  display: block;
  width: 24px;
  height: 2px;
  background-color: #292929;
  position: absolute;
  left: 0;
  transition: all 0.3s ease;
}

.hamburger-top {
  top: 6px;
}

.hamburger-middle {
  top: 12px;
}

.hamburger-bottom {
  top: 18px;
}


.mobile-menu-btn.open .hamburger-top {
  transform: rotate(45deg);
  top: 12px;
}

.mobile-menu-btn.open .hamburger-middle {
  opacity: 0;
}

.mobile-menu-btn.open .hamburger-bottom {
  transform: rotate(-45deg);
  top: 12px;
}


@media (max-width: 1024px) {
  .mobile-menu-btn {
    display: block;
  }

  .nav-links {
    position: fixed;
    right: 0;
    top: 80px; 
    flex-direction: column;
    background-color: white;
    width: 200px;
    padding: 1rem;
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.1);
    transform: translateX(100%);
    transition: transform 0.3s ease;
    gap: 1rem;
    height: auto;
    background-color: rgba(238, 248, 237, 0.95);
    border-radius: 0 0 0 15px; 
    border-left: 2px solid #07d600;
  }

  .nav-links.active {
    transform: translateX(0);
  }

  .nav-links li {
    height: auto; 
  }

  .nav-links li a {
    padding: 0.75rem 0; 
    height: auto; 
  }
}

.logo-img {
  width: 80px; 
  height: auto; 
  filter: brightness(1.23);
  background-color: transparent; 
}

.logo {
  display: inline-block;
  background-color: transparent;
  padding-top: 10px; 
}


