.footer {
  position: relative;
  padding: 2rem 0;
  background-color: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(5px);
  border-top: 2px solid #07d600;
  width: 100%;
}

.footer-content {
  max-width: 80%;
  margin: 0 auto; 
  padding: 0 2rem;
  display: flex;
  justify-content: flex-start; 
  gap: 4rem; 
  align-items: center;
}

.social-links {
  flex: 1; 
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: flex-start; 
}


.social-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: rgb(38, 38, 38);
  transition: transform 0.3s ease, color 0.3s ease;
  gap: 0.5rem;
}

.social-link:hover {
  transform: translateY(-2px);
  color: #07d600;
}

.footer-icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.icon-label {
  font-size: 0.75rem;
  font-weight: 500;
  opacity: 0;
  transform: translateY(-5px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.social-link:hover .icon-label {
  opacity: 1;
  transform: translateY(0);
}


.coded-by {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: rgb(38, 38, 38);
  letter-spacing: 0.5px;
  justify-content: center;
}

.coded-text {
  font-weight: 400;
}

.react-icon {
  color: #61DAFB;
  width: 20px;
  height: 20px;
}

.react-text {
  font-weight: 600;
  color: #61DAFB;
}


.spin {
  animation: spin 10s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.signature-container {
  flex: 1; 
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.footer-signature {
  height: 60px;
  width: auto;
  max-width: 300px;
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

.footer-signature:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .footer-content {
    padding: 0 2rem; 
    flex-direction: column;
    gap: 2rem;
  }
  
  .social-links,
  .coded-by,
  .signature-container {
    flex: none; 
    width: 100%;
    justify-content: center;
  }

  .coded-by {
    order: 2;
  }
  
  .signature-container {
    order: 1;
  }
  
  .footer-signature {
    max-width: 250px;
  }
}