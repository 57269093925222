
body {
  background-color: rgb(238, 247, 238);
}

.home-wrapper {
  position: relative;
  isolation: isolate;
  background: transparent;
  min-height: 100vh;
}

@keyframes riseAndFade {
  0% {
    transform: translateY(8vh);
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0.2;
  }
}

@keyframes layoutReveal {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.dot-pattern-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background-image: url('/src/assets/bkg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: none;
  background-color: transparent;
  animation: riseAndFade 2.3s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
}


.layout-element {
  opacity: 0;
  animation: layoutReveal 1s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
}

.delay-1 {
  animation-delay: 0.3s;
}

.delay-2 {
  animation-delay: 0.6s;
}

.delay-3 {
  animation-delay: 0.9s;
}


.layout-animation {
  overflow: visible;
}


.layout-element {
  will-change: transform, opacity;
  backface-visibility: hidden;
}


.layout-element:hover {
  transition: transform 0.3s ease;
  transform: translateY(-2px);
}


@media (max-width: 768px) {
  .layout-element {
    animation-duration: 0.8s; 
  }
}

.home-content {
  min-height: calc(100vh - 80px);
  padding: 8rem 1.5rem;
  width: 100%;
  position: relative;
  
  background: transparent;
}


.home-container,
.projects-container,
.vertical-text,
.projects-section {
  background: transparent;
}

.vertical-text {
  writing-mode: vertical-lr;
  transform: rotate(360deg);
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-size: 4rem;
  letter-spacing: 0.1em;
  color: #9ca3af;
  padding: 2rem 0;
  margin-right: 6rem;
  position: relative;
  height: max-content;
}

.projects-section {
  margin: 1rem 0;
  display: grid;
  gap: 16rem;
  padding: 2rem 0;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
}

@media (max-width: 1024px) {
  .home-container,
  .projects-container {
    width: 95%;
  }
}

@media (max-width: 768px) {
  .home-content {
    padding: 2rem 1rem;
  }

  .vertical-text {
    font-size: 2.5rem;
    margin-right: 1.5rem;
  }
}