.masonry-gallery {
  display: flex;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

.masonry-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.masonry-item {
  display: block;
  width: 100%;
  break-inside: avoid;
}

.masonry-image {
  width: 100%;
  height: auto;
  display: block;
}

@media (max-width: 900px) {
  .masonry-gallery {
    gap: 10px;
    padding: 10px;
  }
  
  .masonry-column {
    gap: 10px;
  }
}

@media (max-width: 600px) {
  .masonry-gallery {
    display: block;
  }
  
  .masonry-item {
    margin-bottom: 10px;
  }
  
  .masonry-item:last-child {
    margin-bottom: 0;
  }
}

.vertical-text2 {
  transform: rotate(360deg);
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-size: 4rem;
  letter-spacing: 0.1em;
  color: #9ca3af;
  padding: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  position: relative;
  height: max-content;
  gap: 5rem;
}

.gallery-container {
  background-color: rgb(238, 248, 237, 0.5);
  min-height: 100vh; 
  max-width: 100vw; 
  margin-top: 10rem; 
  padding: 0; 
  position: relative; 
  left: 0; 
  right: 0; 
  overflow-x: hidden; 
  align-items: center;
}


.gallery-content {
  padding: 180px;
  display: flex;
  gap: 40px;
  align-items:center;
}


.pdf-document {
  width: 100%;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  min-height: 200px;
}

.pdf-document .react-pdf__Page {
  width: 100% !important;
  height: auto !important;
}

.pdf-document .react-pdf__Page canvas {
  width: 100% !important;
  height: auto !important;
  object-fit: contain;
}


.pdf-document.react-pdf__Document--loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 14px;
  color: #666;
}

.disclaimer {
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.05em;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5;
  margin-left: 2.5rem;
  margin-bottom: 2rem;
}