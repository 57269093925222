.projects-container {
    max-width: 80rem;
    margin: 0 auto;
    padding: 0 1.5rem;
    display: flex;
    position: relative;
  }
  
  .vertical-text {
    writing-mode: vertical-lr;
    transform: rotate(360deg);
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-size: 4rem;
    letter-spacing: 0.1em;
    color: #9ca3af;
    padding: 2rem 0;
    margin-right: 6rem;
    position: relative;
    height: max-content;
  }
  
  .projects-wrapper {
    width: 100%;
  }
  
  .projects-section {
    margin: 2rem 0;
    display: grid;
    gap: 6rem;
    padding: 2rem 0;
    font-family: 'Montserrat', sans-serif;
  }
  
  .project-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
    align-items: center;
    position: relative;
  }
  
  .project-card::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle at center, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%);
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }
  
  .project-card:hover::before {
    opacity: 0.5;
  }
  
  .project-info {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .project-info h2 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #000;
    margin: 0;
  }
  
  .project-info p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #4b5563;
    margin: 0;
  }
  
  .view-project {
    background: transparent;
    border: none;  
    color: inherit;
    cursor: pointer;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
  }

  .view-project:focus {
    outline: none;  
  }
  
  .view-project:hover {
    gap: 0.75rem;
  }
  
  .arrow-icon {
    transition: transform 0.3s ease;
  }
  
  .view-project:hover .arrow-icon {
    transform: translate(2px, -2px);
  }
  
  .project-image {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .project-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }


  .project-image {
    transition: transform 0.3s ease;
  }
  
  .project-image:hover {
    transform: scale(1.02);
  }

  

  @media (max-width: 768px) {

  
    
    .projects-wrapper {
      padding: 0 1rem;
    }
  
    .project-card {
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }
  
    .project-info h2 {
      font-size: 2rem;
    }
    .projects-container {
      padding: 0 1rem;
    }
  
    .vertical-text {
      font-size: 2.5rem;
      margin-right: 1.5rem;
    }
  }