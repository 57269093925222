* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
    width: 100%;
    overflow-x: hidden; 
  }


  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content {
    flex: 1;
    position: relative;
    z-index: 0; 
  }
  
  header {
    z-index: 1; 
  }
  
  footer {
    z-index: 1; 
  }
  