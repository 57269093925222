
.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    text-align: center;
    background-color: transparent;
  }
  
  .not-found-container h1 {
    font-size: 5rem;
    margin-bottom: 20px;
  }
  
  .not-found-container p {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  
  .back-home-link {
    font-size: 1.2rem;
    text-decoration: none;
    color: #07d600;
    border: 2px solid #07d600;
    padding: 10px 20px;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  
  .back-home-link:hover {
    background-color: #07d600;
    color: white;
  }
  
  img {
    width: 8rem;
    height: 12rem;
  }